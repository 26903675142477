<template>
  <Layout>
    <div
      style="
        text-align: center;
        font-size: 18px;
        font-weight: 900;
        color: #d31616;
      "
    >
      Not Authorized
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
export default {
  components: {
    Layout,
  },
};
</script>
